import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import { Text, Image } from "../components/Core"
import { ImageText, FullImageText } from "../components/ImageText"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { AnchorLinksBar } from "../components/AnchorLinksBar"

const EducationPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    title,
    hero,
    anchors,
    heading,
    watchImageText,
    studentCruises,
    fieldTrips,
    curriculum,
    library
  } = data.allUniquePagesJson.nodes[0]

  const rootUrl = title.substring(0, title.length - 1)

  return (
    <Layout className="education">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Image useAR publicId={hero.image} />

      <AnchorLinksBar rootUrl={rootUrl} links={anchors} />

      <Section>
        <Container>
          <Text as="h1" text={heading} />
        </Container>
      </Section>

      <FullImageText
        reverse={watchImageText.reverse}
        image={watchImageText.image}>
        <div>
          <Text
            className="mb-3 has-text-centered-mobile"
            as="h3"
            text={watchImageText.text}
          />
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${watchImageText.buttons[0].button.youtube}`}
            controls
            playing
            iconButton
            language={"en"}
          />
        </div>
      </FullImageText>

      <Section className="student-cruises">
        <Container small>
          <div className="even-grid--2 mobile-collapse">
            <Text text={studentCruises.left} />
            <Text
              className="student-cruises__color-back"
              text={studentCruises.right}
            />
          </div>
        </Container>
      </Section>

      <Section>
        <Container small>
          <ImageText text={fieldTrips.text} image={fieldTrips.image} />
        </Container>
      </Section>

      {/* <Section>
        <Container small>
          <Text text={curriculum.heading} />
          <div className="even-grid--2 mobile-collapse mt-4">
            <Text text={curriculum.left} />
            <Text className="mt-2--mobile" text={curriculum.right} />
          </div>
        </Container>
      </Section> */}

      <Section className="education__library">
        <Container small>
          <Text text={library.heading} />
          <div className="even-grid--2 mobile-collapse mt-4">
            <Text text={library.left} />
            <Text className="mt-2--mobile" text={library.right} />
          </div>
          <Text className="mt-4" text={library.resources} />
        </Container>
      </Section>
    </Layout>
  )
}

export const educationQuery = graphql`
  query educationquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
        }
        anchors {
          text
          id
        }
        heading
        watchImageText {
          image
          text
          reverse
          buttons {
            button {
              youtube
            }
          }
        }
        studentCruises {
          left
          right
        }
        fieldTrips {
          text
          image
        }
        # curriculum {
        #   heading
        #   left
        #   right
        # }
        library {
          heading
          left
          right
          resources
        }
      }
    }
  }
`

export default EducationPage
