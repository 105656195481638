import React from "react"
import classNames from "classnames"
import Text from "../Text"
import { Button, ButtonGroup } from "../Buttons"
import { Image } from "../Core"

const ImageText = ({
  text,
  image,
  buttons,
  customButton,
  reverse,
  className
}) => {
  const wrapperClasses = classNames("img-txt", className, {
    reverse
  })
  return (
    <div className={wrapperClasses}>
      <div className="img-txt__text">
        <Text text={text} />
        {buttons && (
          <ButtonGroup>
            {buttons.map(btn => (
              <Button
                key={btn.button.href}
                appearance={btn.button.appearance}
                destination={btn.button.destination}
                href={btn.button.href}
                buttonText={btn.button.buttonText}
                youtube={btn.button.youtube}
              />
            ))}
          </ButtonGroup>
        )}
        {customButton && customButton}
      </div>
      <div className="img-txt__image">
        <Image publicId={image} />
      </div>
    </div>
  )
}

export default ImageText
